.container {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: var(--color-background-translucent);
  z-index: var(--now-playing-ad-z-index);
  box-shadow: var(--fade-box-shadow);
  
  &.withPlayer {
    bottom: var(--player-height);
  }
  
  &.withBorder {
    border-bottom: 1px solid var(--grey-poupon);
  }
}
