.bar {
  width: 100%;

  @media (--min-width-small) {
    width: auto;
  }

  @media (--min-width-xxlarge) {
    margin: auto;
  }
}

.title {
  font-weight: bold;
  font-size: 10px;
  color: var(--grey-6);
  margin-bottom: -1px;
  line-height: 13px;
  letter-spacing: var(--letter-spacing-loose);
  text-transform: uppercase;
  user-select: none;
  cursor: pointer;

  &.selected {
    color: var(--secondary-color-5);
  }

  .mobile & {
    line-height: 11px;
  }

  :global(html.ford) & {
    color: var(--text-color);
    font-size: var(--font-size-2);
    text-transform: none;

      &.selected {
        color: var(--secondary-color-5);
      }
  }
}
