.browsiesHeader {
  padding: var(--discord-browsies-header-top-padding) 0 16px
    var(--discord-content-y-padding);

  :global(html.discord.infoBannerVisible) & {
    padding-top: calc(
      var(--discord-browsies-header-top-padding) - var(--safe-area-inset-top)
    );
  }
}

.categoryBrowsiesHeader {
  position: sticky;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--content-area-background-color-hex);
  row-gap: 10px;
  margin-bottom: 5px;
  z-index: var(--top-z-index);
  transform: translateY(
    -1px
  ); /* there's a 1px gap between browsies and the top of the Discord iframe. Couldn't find a better way, but will explore more later and revisit. */

  &.showBoxShadow {
    box-shadow: 0 16px 16px rgb(0 0 0 / 40%);
  }

  &.hide {
    display: none;
  }

  @media (--min-width-small) {
    flex-direction: row;
    padding-bottom: 10px;
  }
}

.navAndControls {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-right: 12px;
  z-index: var(--top-z-index);

  :global(html.discord.mobile) & {
    margin-bottom: 12px;
  }

  @media (--min-width-small) {
    position: absolute;
    width: auto;
    left: 10px;
  }

  .discordControls {
    position: relative;

    @media (--min-width-small) {
      display: none;
    }

    .discordControlsButton {
      border: none;
      background-color: transparent;
    }

    .discordControlsPopover {
      top: calc(100% + 4px);
      right: 0;
    }
  }
}

.primaryBadge {
  z-index: var(--top-z-index);

  @media (--min-width-xxsmall) {
    z-index: inherit;
  }

  a {
    display: block;
  }
}

.backAndTitle {
  display: flex;
  align-self: flex-start;
}

.title {
  font-size: 22px;
  font-weight: bold;
  color: white;
}
