.container {
  overflow-x: hidden;
  padding-top: var(--right-side-top-padding);
  padding-right: var(--right-side-right-padding);
  padding-bottom: var(--right-side-bottom-padding);

  @media (--min-width-large) {
    padding-top: 18px;
  }
}

.wrapper {
  display: none;
  pointer-events: none;

  @media (--min-width-xlarge) {
    display: block;
  }
}
