.app {
  position: absolute;
  top: 0;
  bottom: 0;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  background-color: var(--content-area-background-color-hex);
  z-index: var(--app-z-index);

  :global(html.discord:not(.mobile)) & {
    bottom: var(--player-height);
  }

  :global(html.ford:not(.mobile)) & {
    bottom: 0;
  }

  &.appWithPlayer {
    bottom: var(--player-height);
  }

  .content {
    min-height: 80%;
    margin-top: var(--site-header-height);

    &.noHeader {
      height: 100%;

      :global(html.discord) & {
        min-height: auto;
        height: auto;
      }
    }
  }

  &.noHeader,
  &.isLanding {
    top: 0;
    z-index: 1;

    .content {
      margin-top: 0;
    }
  }

  .leftSideOpen {
    @media (--min-width-large) {
      padding-left: var(--leftSide-width-px);

      &.homeLeftSideOpen {
        padding-left: 0;
      }
    }
  }

  .rightSideOpen {
    @media (--min-width-xlarge) {
      padding-right: var(--rightSide-width-padding-px);
    }
  }

  @media (--min-width-large) {
    .content {
      margin-top: 0;

      &.mobile:not(.isLanding, .noHeader) {
        margin-top: var(--site-header-height);
      }

      &.isMinimalHeader:not(.noHeader) {
        margin-top: var(--site-header-height);
      }
    }
  }
}

.leftSideOffset {
  left: calc(50% + var(--leftSide-width-px));
  transform: translateX(calc(-50% - (var(--leftSide-width-px) / 2)));
}

.rightSideOffset {
  left: calc(50% + var(--rightSide-width-px));
  transform: translateX(calc(-50% - (var(--rightSide-width-px) / 2)));
}

.leftSideOffset.rightSideOffset {
  left: calc(50% + (var(--leftSide-width-px) - var(--rightSide-width-px)));
  transform: translateX(
    calc(
      -50% - ((var(--leftSide-width-px) / 2) - (var(--rightSide-width-px) / 2))
    )
  );
}
