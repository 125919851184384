.topBanner {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: var(--top-banner-height);
  padding-top: 0;
  padding-bottom: 0;
  color: white;
  background-color: var(--ink-dark);
  overflow-y: hidden;
}

.messageWrapper {
  display: flex;

  .updateIcon {
    width: 16px;
    height: 16px;
    margin-right: 0.5rem;
    background: transparent url('/assets/img/shared/download-white.svg')
      no-repeat top right;
    background-size: 16px 16px;
  }
}

.installButton {
  margin: 0 19px 0 48px;
  width: 136px;

  @media (--max-width-xxlarge) {
    width: 110px;
  }

  @media (--max-width-medium) {
    margin: 0 19px 0 32px;
    width: 85px;
  }

  @media (--max-width-xsmall) {
    margin: 0 19px;
    width: 60px;
  }
}
