.backButton {
  display: none;
  position: relative;
  border: none;
  background: none;
  padding: 0;
  margin-right: 15px;
  margin-top: 0;

  @media (--min-width-xxsmall) {
    display: block;

    :global(html.discord) & {
      background-color: rgb(0 0 0 / 40%);
      border-radius: 25px;
    }
  }
}
