.pillBar {
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
  overflow-x: scroll;
  white-space: nowrap;
  scrollbar-width: none; /* hide scrollbar in Firefox */

  /* hide scrollbar in WebKit browsers */
  &::-webkit-scrollbar {
    display: none;
    appearance: none;
    width: 0;
    height: 0;
  }
}
