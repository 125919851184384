.pill {
  display: inline-flex !important;
  align-items: center;
  justify-content: space-between;
  background-color: var(--medium-grey);
  backdrop-filter: var(--map-view-overlay-component-backdrop-filter);
  border: none;
  border-radius: 17px;
  padding: 8px 16px;
  margin-right: 10px;
  max-width: 100%;
  height: 28px;
  user-select: none;

  .mobile & {
    padding: 10px 14px;
  }

  @media (--min-width-xxlarge) {
    padding: 10px 14px;
  }

  &:hover:not(.selected) {
    background-color: var(--anti-flash-white);
    opacity: 0.7;

    > * {
      color: var(--secondary-color-5);
    }

    path {
      stroke: var(--secondary-color-5);
    }
  }

  &.selected {
    background-color: var(--anti-flash-white);

    .title {
      color: var(--yankees-blue);
    }
  }
}

.link {
  composes: link from '../../../styles/common.module.scss';
  user-select: none;
}
