.footer {
  composes: contentContainer from '../../styles/common.module.scss';
  margin-top: 20px;
  padding-top: 65px;

  &.noRight {
    max-width: var(--content-max-width-closedright-px);
  }

  &.isMinimal.noRight {
    padding-top: 32px;
    padding-bottom: 0;
    max-width: var(--content-max-width-px);
  }

  :global(html.ford) & {
    margin-inline: 16px;
  }
}

.footerRow {
  width: 100%;
  margin-bottom: 25px;
  clear: both;
  overflow: auto;
  backface-visibility: hidden; /* property that can prevent flickering for transitions */

  &.noOverflow {
    overflow: hidden;
  }
}

.footnoteContainer {
  font-size: var(--font-size-1);
  text-align: center;

  @media (--min-width-medium) {
    display: inline-block;
    text-align: left;
  }
}

.legalContainer {
  margin: 5px 0;

  @media (--min-width-medium) {
    display: inline-block;
    margin-right: 15px;
  }
}

.policiesContainer {
  @media (--min-width-medium) {
    display: inline-block;
  }

  .siteLink {
    display: inline-block;
    margin-right: 15px;
    font-size: var(--font-size-1);

    &:last-child {
      margin-right: 0;
    }
  }
}

.linksColumn {
  .siteLink {
    display: flex;
    align-items: center;
  }
}

.socialLinksContainer {
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  margin-top: 25px;

  @media (--min-width-medium) {
    margin-top: 0;
    display: inline-block;
    text-align: right;
    float: right;
  }
}

.socialLink {
  display: inline-flex;
  align-items: center;
  margin-right: 20px;
  width: 24px;
  height: 24px;

  &:hover {
    text-decoration: none;
    opacity: 0.8;
  }

  @media (--min-width-medium) {
    margin-right: 25px;
  }

  @media (--min-width-large) {
    margin-right: 15px;

    &.noRight {
      margin-right: 25px;
    }
  }

  &:last-child {
    margin-right: 0;
  }
}

.socialLinkFacebook {
  background: transparent url('/assets/img/footer/facebook-ink.svg') no-repeat;
  background-size: contain;
}

.socialLinkInstagram {
  background: transparent url('/assets/img/footer/instagram-ink.svg') no-repeat;
  background-size: contain;
}

.socialLinkTwitter {
  background: transparent url('/assets/img/footer/x-ink.svg') no-repeat;
  background-size: contain;
}

.socialLinkLinkedIn {
  background: transparent url('/assets/img/footer/linkedin-ink.svg') no-repeat;
  background-size: contain;
}

.logoContainer {
  display: block;

  @media (--min-width-medium) {
    display: inline-block;
  }
}

.logo {
  background: transparent url('/assets/img/footer/tunein-logo-ink.svg')
    no-repeat;
  background-size: contain;
  vertical-align: top;
  width: 91px;
  height: 40px;
  display: inline-block;

  @media (--min-width-medium) {
    display: block;
  }
}

.valuePropContainer {
  display: block;
  margin-top: 15px;
  width: 100%;
  font-size: var(--font-size-1);
  line-height: 1.5;

  @media (--min-width-medium) {
    display: block;
    margin-top: 15px;
    width: 110px;

    &.noRight {
      width: 200px;
    }
  }

  @media (--min-width-xxlarge) {
    width: 200px;
  }
}

.linksContainer {
  display: block;
  vertical-align: top;
  float: none;

  @media (--min-width-medium) {
    margin-top: 10px;
    display: inline-block;
    float: right;
  }
}

.linksRow {
  display: block;
  vertical-align: top;

  @media (--min-width-xsmall) {
    margin-top: 25px;
  }

  @media (--min-width-medium) {
    display: inline-block;
    margin-top: 0;
  }

  .linksColumn {
    display: inline-block;
    vertical-align: top;
    width: 48%;
    margin-top: 20px;

    @media (--min-width-medium) {
      margin-top: 0;
      width: 127px;

      &.noLinkHeader {
        width: auto;
        margin-right: 30px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.siteLink {
  display: block;
  font-size: var(--font-size-2);
  line-height: 18px;
  padding: 5px 0;
  
  &:hover,
  &:focus {
    color: var(--cloud-grey);

    i {
      opacity: 0 !important;
    }
  }
}

.chevron {
  width: 13px;
  height: 13px;
  fill: white;
  transform: translateY(1px);
}

.siteLinkHeader {
  composes: siteLink;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-4);
  font-weight: var(--font-weight-medium);
  margin-bottom: 10px;

  &:hover,
  &:focus {
    color: var(--secondary-color-5);

    i {
      opacity: 1 !important;
    }
  }
}

.isLanding {
  background-color: var(--secondary-color-5);
  color: white;

  .footer {
    margin-top: 0;
    padding-top: 64px;
    padding-left: 32px;
    padding-right: 32px;
    max-width: var(--landing-max-width);

    @media (--min-width-large) {
      padding-left: 36px;
      padding-right: 36px;
    }

    @media (--min-width-xlarge) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .logo {
    background: transparent url('/assets/img/footer/tunein-logo-tsharp.svg')
      no-repeat;
    background-size: contain;
    width: 128px;
    height: 56px;
  }

  .valuePropContainer {
    width: 100%;
    margin-top: 15px;

    @media (--min-width-large) {
      width: 110px;

      &.noRight {
        width: 200px;
      }
    }

    @media (--min-width-xxlarge) {
      width: 200px;
    }
  }

  .siteLink {
    color: white;

    i {
      background-color: white !important;
    }

    &:hover,
    &:focus {
      color: white;

      i {
        opacity: 1 !important;
      }
    }
  }

  .footerRow {
    margin-bottom: 64px;

    @media (--min-width-large) {
      margin-bottom: 100px;
    }
  }

  .linksContainer {
    @media (--min-width-medium) {
      display: block;
      float: none;
    }

    @media (--min-width-large) {
      display: inline-block;
      float: right;
    }
  }

  .linksRow {
    @media (--min-width-small) {
      display: inline-block;
      width: 50%;
      margin-top: 0;
    }

    @media (--min-width-large) {
      width: auto;
    }

    .linksColumn {
      margin-top: 40px;
      width: 50%;

      @media (--min-width-medium) {
        &.noLinkHeader {
          margin-right: 0;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      @media (--min-width-large) {
        width: 140px;
        margin-top: 0;
        margin-right: 40px;

        &.noLinkHeader {
          margin-right: 40px;
        }
      }
    }
  }

  .socialLinkFacebook {
    background: transparent url('/assets/img/footer/facebook-white.svg')
      no-repeat;
    background-size: contain;
  }

  .socialLinkInstagram {
    background: transparent url('/assets/img/footer/instagram-white.svg')
      no-repeat;
    background-size: contain;
  }

  .socialLinkTwitter {
    background: transparent url('/assets/img/footer/x-white.svg') no-repeat;
    background-size: contain;
  }

  .socialLinkLinkedIn {
    background: transparent url('/assets/img/footer/linkedin-white.svg') no-repeat;
    background-size: contain;
  }
}

.srOnly {
  composes: screenReaderOnly from '../../styles/common.module.scss';
}

.darkMode {
  color: white;

  .legalContainer {
    opacity: 0.5;
  }

  .siteLink {
    opacity: 0.5 !important;
    color: white;

    i {
      background-color: white !important;
    }

    &:hover,
    &:focus {
      opacity: 1 !important;

      i {
        opacity: 1 !important;
      }
    }
  }
}
