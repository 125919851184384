.message {
  position: fixed;
  padding: 10px 50px;
  background-color: white;
  opacity: 0;
  border-radius: 6px;
  box-shadow: 0 0 16px 0 rgb(0 0 0 / 10%);
  text-align: center;
  left: 50%;
  bottom: 24px;
  transform: translate(-50%, 0);
  transition: all 300ms ease-in;
  pointer-events: none;
  z-index: var(--above-top-z-index);

  @media (--max-width-small) {
    padding: 5px 15px;
  }

  :global(html.discord) & {
    background-color: var(--anti-flash-white);
    color: black;
    padding: 10px 20px;
    border-radius: 18px;
  }
}

.show {
  opacity: 1;
  transform: translateX(-50%);
}

.abovePlayer {
  bottom: calc(var(--player-height) + 16px);
}
