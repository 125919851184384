.button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  border: none;
  background-color: transparent;
  user-select: none;

  &.disabled {
    cursor: default;
  }
}

.text,
.text span {
  font-size: var(--font-size-1);
  color: var(--anti-flash-white);
  white-space: nowrap;
  user-select: none;

  &.wrap {
    white-space: normal;
  }

  &.left {
    text-align: left;
  }
}

.bold {
  font-weight: bold;
}

.avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 6px;
}

.actionButton {
  height: 29px;
  max-width: 181px;
  background-color: black;
  padding: 0 3px;
  border: 2px solid var(--medium-grey);
  border-radius: 20px;
  box-sizing: content-box;

  &.open {
    border-color: var(--discord-purple);
  }

  > .text {
    display: none;
    margin-right: 6px;
    text-align: left;

    @media (--min-width-small) {
      display: block;
    }

    > span {
      font-weight: bold;
    }
  }

  .arrow {
    margin-right: 5px;
    color: var(--anti-flash-white);

    &.up {
      transform: scaleY(-1) translateY(1px);
    }
  }
}

.popover {
  right: 75px;
  width: 260px;
  border-radius: 9px;
  margin-bottom: 10px;
  bottom: var(--player-height);

  .popoverContent {
    padding: 14px 22px 0;
    overflow: hidden;

    &.hostOnly {
      padding-bottom: 14px;
    }

    hr {
      background-color: var(--secondary-color-3);
      height: 1px;
      margin: 5px 0 10px;
    }

    .modeContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 32px;
      margin-bottom: 11px;

      .text {
        white-space: normal;
      }
    }

    .userListLabel {
      display: block;
      font-size: 10px;
      margin-bottom: 11px;
    }

    .participantsContainer {
      overflow-y: scroll;
      max-height: 72px;

      .participant {
        margin-bottom: 6px;

        &:last-of-type {
          margin-bottom: 14px;
        }

        &.single:not(:last-of-type) {
          margin-bottom: 0;
        }

        .text {
          font-weight: bold;
          color: var(--secondary-color-4);
        }
      }
    }
  }
}
