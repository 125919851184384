.infoBanner {
  position: relative;
  display: none;
  justify-content: center;
  align-items: center;
  gap: 4px;
  height: var(--info-banner-height);
  padding-top: var(--safe-area-inset-top);
  background-color: var(--medium-grey);
  opacity: 0.6;

  @media (--min-width-xxsmall) {
    display: flex;
  }

  .message {
    color: var(--anti-flash-white);
    font-size: 11px;
  }
}
