.switchButtonContainer {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 42px;
  max-height: 14px;
  padding: 0;
  border-radius: 20px;
  border-width: 0;
  outline: none;
  background-color: var(--secondary-color-3);
  cursor: pointer;

  &.enabled {
    justify-content: flex-end;
    background-color: var(--enabled-green);
  }
}

.switchButton {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border-width: 0;
  background-color: var(--white);
  box-shadow:
    -2px 3px 3px 0 rgb(0 0 0 / 10%),
    0 3px 14px 0 rgb(0 0 0 / 8%),
    0 0 3px 0 rgb(0 0 0 / 20%);
  z-index: 1;
}

.icon {
  z-index: 0;
}
